import * as React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Layout from '../../components/Layout'
import banner_1_0 from '../../images/news/IAC2021/banner_1_0.jpg'
import banner_1_1 from '../../images/news/IAC2021/banner_1_1.jpg'
import banner_1_2 from '../../images/news/IAC2021/banner_1_2.jpg'
import img_2_0 from '../../images/news/IAC2021/img_2_0.jpg'
import img_2_1 from '../../images/news/IAC2021/img_2_1.jpg'
import img_2_2 from '../../images/news/IAC2021/img_2_2.jpg'
import img_3_0 from '../../images/news/IAC2021/img_3_0.jpg'
import img_3_1 from '../../images/news/IAC2021/img_3_1.jpg'
import img_3_2 from '../../images/news/IAC2021/img_3_2.jpg'


const lorem = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "

export default function PostPage() {
    return (
        <Layout title='Conex&apos;s Visit to IAC 2021 | Conex Research'>
            <section className='w-screen h-fit mt-6 mb-20'>

                <div className='px-8 lg:px-20'>

                    <h5 className='mb-10 sm:hidden'><a href='/media'>{'< '}Back to News</a></h5>
                    <h3 className='text-xl ws:text-3xl font-light text-slate-700 mb-3'>20 Mar 2022</h3>
                    <h1 className='text-5xl sm:text-7xl ws:text-8xl font-semibold mb-3'>Conex's visit to the IAC 2021</h1>
                    <h2 className='text-xl ws:text-3xl sm:text-2xl mb-3'>Presenting the Arcanum mission to members of the industry</h2>

                    <div className='grid grid-rows-1 grid-cols-2'>

                        <h3 className='font-light text-slate-700 justify-self-start ws:text-2xl'>By <a className='text-conex-blue' href='https://twitter.com/jemckevitt' target='_blank'>James McKevitt</a></h3>

                        <div className='w-full flex flex-row justify-end gap-3'>

                            <a href={'https://twitter.com/intent/tweet?url=https%3A%2F%2Fconexresearch.com%2Fnews%2FIAC2021&via=ConexResearch&text=Conex%20at%20IAC%202021'} title="Share on Twitter" target='_blank' className="flex items-center p-1">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-5 h-5 text-conex-blue">
                                    <path d="M23.954 4.569a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.061a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.937 4.937 0 004.604 3.417 9.868 9.868 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 002.46-2.548l-.047-.02z"></path>
                                </svg>
                            </a>

                        </div>
                        
                    </div>

                </div>

                <div className='w-full h-fit mt-20'>
                    
                    <Carousel autoPlay={true} infiniteLoop={true} interval={4000} transitionTime={1000} showThumbs={false} showStatus={false}>
                        <div>
                            <img src={banner_1_0}/>
                        </div>
                        <div>
                            <img src={banner_1_1}/>
                        </div>
                        <div>
                            <img src={banner_1_2}/>
                        </div>
                    </Carousel>

                </div>

            </section>

            <section className='w-screen max-w-full'>

                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    <strong className='font-semibold'>Background</strong><br/>

                    As an international and virtual-working research group, Conex will always take advantage of any opportunity to meet in person. The International Astronautical Congress in Dubai presented just such an opportunity, and a perfect chance to show the latest progress the team has made on the Arcanum mission to the space community.
                    The International Astronautical Congress (IAC) is the largest space event in the calendar, bringing together engineers, scientists, policymakers, industry partners and other stakeholders around a nucleus of discussion about current and future developments in the space sector.
                    Dubai provided a perfect venue for the conference this year, with the UAE currently taking successful and concrete steps on an ambitious roadmap of space exploration. A tour of the Mohammed Bin Rashid Space Centre taken by Conex showed their commitment to this programme of discovery, and it is clear that the country has long-term aspirations as a world leader in technology in general, as seen at the impressive Expo 2020.


                    <br/><br/><br/><strong className='font-semibold'>Our experience at IAC</strong><br/>

                    IAC is always a large bustling group of space professionals and enthusiasts, and 2021 was no different! With lots of different parallel activities, the Dubai World Trade Centre was a hive of activity all week: catch-ups, meetings, trade show stands, technical presentations and interactive poster sessions meant an action-packed day for all participants.
                    The trade show provided a great opportunity to speak to high-level players in the industry. We enjoyed a thorough discussion with Dr Janet Kavandi about the just-announced Orbital Reef Station and the technical and geopolitical challenges of the endeavour, and a memorable interaction with Lockheed Martin representatives about our own project!
                    The stands of such companies also offered countless promotional giveaways, all of which made it into at least one of our suitcases…

                    
                    <br/><br/><br/><strong className='font-semibold'>IAC Sessions</strong><br/>

                    IAC is always a large bustling group of space professionals and enthusiasts, and 2021 was no different! With lots of different parallel activities, the Dubai World Trade Center was a hive of activity all week: catch-ups, meetings, trade show stands, technical presentations and interactive poster sessions meant an action-packed day for all participants.
                    The trade show provided a great opportunity to speak to high-level players in the industry. We enjoyed a thorough discussion with Dr Janet Kavandi about the just-announced Orbital Reef Station and the technical and geopolitical challenges of the endeavor, and a memorable interaction with Lockheed Martin representatives about our own project!
                    The stands of such companies also offered countless promotional giveaways, all of which made it into at least one of our suitcases…

                    
                </p>

                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                        <img src={img_2_0} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_1} className='rounded-xl'/>
                    </div>
                    <div>
                        <img src={img_2_2} className='rounded-xl'/>
                    </div>
                </div>

                <p className='font-light text-lg ws:text-3xl leading-relaxed px-8 lg:px-64'>

                    After this, discussions about innumerable space topics began! Of increasing concern to all of us is the rise in space debris and the lack of mitigating technologies or initiatives.
                    The Arcanum mission showcases how next-generation launch vehicles can be used to deliver large scientific payloads deep into the Solar System, but such rockets also harbour the potential to place large numbers of much smaller objects into orbit; something which is of concern to all stakeholders in space if more stringent regulation is not introduced.
                    It was encouraging, therefore, that a number of parallel sessions were held with high-ranking industry players to discuss such issues. We can all hope that concrete steps will be taken in the near future.

                    <br/><br/><br/><strong className='font-semibold'>Interactive Presentation</strong><br/>

                    Obviously the main reason for our group’s attendance was to share the Arcanum mission with the space community, and this year we thought we would try an interactive presentation. This involved talking next to the trade show floor in front of a touchscreen presentation showing some graphic representations of the Arcanum spacecraft, the science goals of the mission, some details on Conex Research, and the Arcanum mission video.
                    This presentation format gave us fantastic opportunities to interact with a large number of conference attendees and answer many more questions than usual. This presentation also received an award from the IAF and Northrop Gruman, a topic for another post.
                                        
                </p>

                <div className='flex flex-col md:flex-row gap-10 md:gap-10 lg:gap-20 p-10 md:p-8 lg:p-16 basis-1/3'>
                    <div>
                    <img src={img_3_0} className='rounded-xl'/>
                    </div>
                    <div>
                    <img src={img_3_1} className='rounded-xl'/>
                    </div>
                    <div>
                    <img src={img_3_2} className='rounded-xl'/>
                    </div>
                </div>

                <p className='font-light text-lg ws:text-3xl leading-relaxed mb-20 px-8 lg:px-64'>

                    <strong className='font-semibold'>Gala Dinner</strong><br/>

                    A busy week gave us the opportunity to catch up with old friends from previous IAF conferences and answer more technical questions from interested individuals about our mission and analysis, but all good things must come to an end. A unique and memorable experience for all attending perfectly finished what had been a fantastic week; the Gala Dinner, this year taking place at the foot of the Burj Khalifa!
                    Some of our team had taken the opportunity to travel to the top floor earlier in the day, and witness preparations for the evening, something that certainly didn’t disappoint. Alongside fantastic food and musical entertainment, it provided one last chance to congratulate friends, old and new, for a successful week, and to enjoy our last moments in the spectacular city that is Dubai.
                                        
                </p>

            </section>

        </Layout>
    )
}
